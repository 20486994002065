import { NODE_ENV } from '@/config'
import Vue from 'vue'
import Vuex, { ModuleTree, Store, StoreOptions } from 'vuex'
import { AuthState, authStore } from './auth'

Vue.use(Vuex)

export interface RootState {
  authStore: AuthState
}

const development = NODE_ENV === 'development'

const options: StoreOptions<RootState> = {
  modules: { authStore: authStore as ModuleTree<RootState> },
  strict: development,
}

const store: Store<RootState> = new Vuex.Store<RootState>(options)

export default store
