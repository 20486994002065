interface Config {
  NODE_ENV: 'development' | 'stage' | 'production'
  BASE_URL: string
  VUE_APP_CONFIG_API_PATH: string
  VUE_APP_CONFIG_API_TIMEOUT: number
  VUE_APP_CONFIG_API_VERSION: string
}

const CONFIG = process.env as Config

export const NODE_ENV = CONFIG.NODE_ENV
export const BASE_URL = CONFIG.BASE_URL
export const LOCATION = window.location.origin

export const API = {
  PATH: CONFIG.VUE_APP_CONFIG_API_PATH,
  TIMEOUT: CONFIG.VUE_APP_CONFIG_API_TIMEOUT,
  VERSION: CONFIG.VUE_APP_CONFIG_API_VERSION,
}
