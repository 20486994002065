import { RouteConfig } from 'vue-router'
import { lazyLoadViewComponent } from '@/routes/utils'
import { responses } from './responses'

const dir = 'customers/quotations'

export const quotations: RouteConfig = {
  props: true,
  path: 'quotations',
  component: lazyLoadViewComponent(`${dir}/Index.vue`),
  children: [
    {
      props: true,
      path: '',
      name: 'customer-quotations',
      component: lazyLoadViewComponent(`${dir}/List.vue`),
    },
    {
      props: true,
      path: ':quotationId',
      name: 'customer-quotation',
      component: lazyLoadViewComponent(`${dir}/Id.vue`),
      children: [responses],
    },
  ],
}
