import { RouteConfig } from 'vue-router'
import { lazyLoadViewComponent } from '@/routes/utils'

export const backlogs: RouteConfig = {
  props: true,
  path: 'backlogs',
  component: lazyLoadViewComponent('backlogs/Index.vue'),
  children: [
    {
      props: true,
      path: '',
      name: 'backlogs',
      component: lazyLoadViewComponent('backlogs/List.vue'),
    },
  ],
}
