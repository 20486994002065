import Vue from 'vue'
import Router, { RouterOptions } from 'vue-router'
import routes from '@/routes'
import { applyEvents } from './events'

Vue.use(Router)

const routerOptions: RouterOptions = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
}

const router = new Router(routerOptions)

applyEvents(router)

export default router
