
import { Component, Vue } from 'vue-property-decorator'
import ContigoSpinner from '@/components/ContigoSpinner.vue'
import { appModule } from '@/modules/app/store'

@Component({
  name: 'app',
  components: {
    ContigoSpinner,
  },
  metaInfo: {
    titleTemplate: (title: string) => {
      return title ? `${title} - Contigo` : 'Contigo'
    },
  },
})
export default class App extends Vue {
  get snackbarText() {
    return appModule.snackbar.text
  }

  get snackbarValue() {
    return appModule.snackbar.value
  }

  set snackbarValue(_) {
    appModule.dismissSnackbar()
  }

  get snackbarConfig() {
    return appModule.snackbar.config
  }

  get loading() {
    return appModule.loading
  }

  get loaded() {
    return !appModule.loading
  }

  dismissSnackbar() {
    appModule.dismissSnackbar()
  }
}
