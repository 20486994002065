import { RouteConfig } from 'vue-router'
import { lazyLoadViewComponent } from '@/routes/utils'
import { quotations } from './quotations'
import { products } from './products'

export const customers: RouteConfig = {
  props: true,
  path: 'customers',
  component: lazyLoadViewComponent('customers/Index.vue'),
  children: [
    {
      props: true,
      path: '',
      name: 'customers',
      component: lazyLoadViewComponent('customers/List.vue'),
    },
    {
      path: 'create',
      name: 'customer-create',
      component: lazyLoadViewComponent('customers/Create.vue'),
    },
    {
      props: true,
      path: 'edit/:customerId',
      name: 'customer-edit',
      component: lazyLoadViewComponent('customers/Edit.vue'),
    },
    {
      props: true,
      path: ':customerId',
      name: 'customer',
      component: lazyLoadViewComponent('customers/Id.vue'),
      children: [quotations, products],
    },
  ],
}
