import { API, NODE_ENV } from '@/config'
import { appModule } from '@/modules/app/store'
import Axios, { AxiosError, AxiosResponse } from 'axios'

const BASE_URL = `${API.PATH}/${API.VERSION}`
const TIMEOUT = NODE_ENV === 'production' ? API.TIMEOUT : undefined

const api = Axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const setAuthorizationHeader = (authHeader: string) => {
  api.defaults.headers.Authorization = authHeader
}

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    if (error.isAxiosError) {
      if (!error.response) {
        appModule.showSnackbar({
          text: 'Ha ocurrido un error, por favor contactar su administrador.',
          config: { color: 'error' },
        })
      }
    }
    return error
  }
)

export default api
