import store from '@/store/index'
import Router from 'vue-router'

export const applyEvents = (router: Router) => {
  router.beforeEach((to, _, next) => {
    store.dispatch('authStore/init', to).then(() => {
      if (to.name === 'unauthorized') {
        next()
        return
      }
      if (store.getters['authStore/isUserAuthenticated']) {
        const user = store.state.authStore.user
        if (user?.role === 'BACKOFFICE') {
          next()
        } else {
          next({ name: 'unauthorized', params: {} })
        }
      } else {
        next({ name: 'unauthorized' })
      }
    })
  })
}
