import { setAuthorizationHeader } from '@/core/api'
import Cookies from 'js-cookie'
import jwt, { JwtPayload } from 'jwt-decode'
import { unix } from 'moment'
import { Module } from 'vuex'

interface Jwt extends JwtPayload {
  role: string
}

export interface AuthState {
  accessToken: string | null
  expiration: Date | null
  user: Jwt | null
}

const options = {
  COOKIE_DOMAIN: process.env.VUE_APP_COOKIE_DOMAIN,
  USER_COOKIE_NAME: 'auth.user.token',
} as const

export const authStore = {
  namespaced: true,
  state: () => ({
    accessToken: null,
    expiration: null,
    user: null,
  }),
  getters: {
    isUserAuthenticated(state) {
      return !!state.accessToken
    },
    authHeader(state, getters) {
      return getters.isUserAuthenticated ? `Bearer ${state.accessToken}` : ''
    },
  },
  mutations: {
    SET_ACCESS_TOKEN(state, accessToken: string) {
      state.accessToken = accessToken
    },
    SET_USER(state, user: Jwt) {
      state.user = user
    },
    SET_EXP(state, exp: Date) {
      state.expiration = exp
    },
  },
  actions: {
    async init({ getters, commit }) {
      const accessToken = Cookies.get(options.USER_COOKIE_NAME) ?? null
      if (!accessToken) {
        return
      }
      const _jwt = jwt<Jwt>(accessToken)
      if (!_jwt?.exp) {
        // resetUserAuthState();
        return
      }
      commit('SET_USER', _jwt)
      const expires = unix(_jwt.exp)
      if (expires.isBefore()) {
        // resetUserAuthState();
        return
      }
      commit('SET_EXP', expires.toDate())
      commit('SET_ACCESS_TOKEN', accessToken)
      Cookies.set(options.USER_COOKIE_NAME, accessToken, {
        domain: options.COOKIE_DOMAIN,
        expires: expires.toDate(),
        path: '/',
        sameSite: 'Strict',
      })
      setAuthorizationHeader(getters.authHeader)
    },
    signOut() {
      Cookies.remove(options.USER_COOKIE_NAME, {
        domain: options.COOKIE_DOMAIN,
        path: '/',
      })
    },
  },
} as Module<AuthState, unknown>
