import './firebase'
import './vee-validate'
import './vue-mask'
import './vue-meta'
import './moment'
import vuetify from './vuetify'
import { useVuetifyConfirm } from './vuetify-confirm'

useVuetifyConfirm(vuetify)

export { vuetify }
