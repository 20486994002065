import Vue from 'vue'

const options = {
  currency: 'DOP',
  style: 'currency',
  minimumFractionDigits: 2,
}

const intl = new Intl.NumberFormat('es-DO', options)

export const formatCurrency = (value: number) => intl.format(value)

Vue.filter('formatCurrency', formatCurrency)
