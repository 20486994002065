import { extend } from 'vee-validate'
import moment, { MomentInput, unitOfTime } from 'moment'

extend('lengths', {
  validate(value: string, args) {
    const { lengths, ignoreWhitespace } = args as {
      lengths: number[]
      ignoreWhitespace: boolean
    }
    const _value = ignoreWhitespace ? value.replace(/\s/g, '') : value
    return lengths?.includes?.(_value.length) ?? false
  },
  params: [
    { name: 'lengths', default: [] },
    { name: 'ignoreWhitespace', default: false },
  ],
  message:
    'La cantidad de caracteres en {_field_} debe ser una de las siguientes: {lengths}',
})

extend('luhn', (value: string) => {
  const _value = value.replace(/[^\d]/g, '')
  let sum = 0
  for (let i = _value.length - 1; i >= 0; i--) {
    let d = +_value.charAt(i)
    if (i % 2 === _value.length % 2) {
      d *= 2
    }
    if (d > 9) {
      d -= 9
    }
    sum += d
  }
  return sum % 10 === 0
})

extend('date_before', {
  validate(value: MomentInput, args) {
    const { date, granularity } = args as {
      date: MomentInput
      granularity: unitOfTime.StartOf
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(date, granularity)
  },
  params: [{ name: 'date', default: moment() }, 'granularity'],
})

extend('date_after', {
  validate(value: MomentInput, args) {
    const { date, granularity } = args as {
      date: MomentInput
      granularity: unitOfTime.StartOf
    }
    return moment(value, 'YYYY-MM-DD').isSameOrAfter(date, granularity)
  },
  params: [{ name: 'date', default: moment() }, 'granularity'],
})

/**
 * [ indicates inclusion of a value.
 * ( indicates exclusion.
 * defaults to ()
 */
extend('date_between', {
  validate(value: MomentInput, args) {
    const { start, end, granularity, inclusivity } = args as {
      start: MomentInput
      end: MomentInput
      granularity: unitOfTime.StartOf
      inclusivity?: '()' | '[)' | '(]' | '[]'
    }
    return moment(value, 'YYYY-MM-DD').isBetween(
      start,
      end,
      granularity,
      inclusivity
    )
  },
  params: [{ name: 'date', default: moment() }, 'granularity'],
})
