import { RouteConfig } from 'vue-router'
import { lazyLoadViewComponent } from '@/routes/utils'
import { options } from './options'

const dir = 'customers/quotations/responses'

export const responses: RouteConfig = {
  props: true,
  path: 'responses',
  component: lazyLoadViewComponent(`${dir}/Index.vue`),
  children: [
    {
      props: true,
      path: '',
      name: 'customer-quotation-responses',
      component: lazyLoadViewComponent(`${dir}/List.vue`),
    },
    {
      props: true,
      path: ':responseId',
      name: 'customer-quotation-response',
      component: lazyLoadViewComponent(`${dir}/Id.vue`),
      children: [options],
    },
  ],
}
