import Vue from 'vue'
import VuetifyConfirm, { VuetifyConfirmUseOptions } from 'vuetify-confirm'

const options: VuetifyConfirmUseOptions = {
  icon: 'fa fa-exclamation-triangle',
}

export const useVuetifyConfirm = (vuetify: unknown) => {
  Vue.use(VuetifyConfirm, { vuetify, ...options })
}
