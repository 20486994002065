import { RouteConfig } from 'vue-router'
import { backlogs } from './backlogs'
import { customers } from './customers'
import { dashboard } from './dashboard'
import { lazyLoadViewComponent } from './utils'

const routes: RouteConfig[] = [
  {
    path: '/',
    component: lazyLoadViewComponent('Layout.vue'),
    children: [dashboard, backlogs, customers],
  },
  {
    name: 'unauthorized',
    path: '401',
    component: lazyLoadViewComponent('401.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
]

export default routes
