import { localize } from 'vee-validate'

const es = {
  code: 'es',
  messages: {
    alpha: 'El campo {_field_} solo debe contener letras',
    alpha_dash:
      'El campo {_field_} solo debe contener letras, números y guiones',
    alpha_num: 'El campo {_field_} solo debe contener letras y números',
    alpha_spaces: 'El campo {_field_} solo debe contener letras y espacios',
    between: 'El campo {_field_} debe estar entre {min} y {max}',
    confirmed: 'El campo {_field_} no coincide',
    digits: 'Debe ser numérico y contener exactamente {length} dígitos.',
    dimensions:
      'El campo {_field_} debe ser de {width} píxeles por {height} píxeles',
    email: 'Correo electrónico invalido.',
    excluded: 'El campo {_field_} debe ser un valor válido',
    ext: 'El campo {_field_} debe ser un archivo válido',
    image: 'El campo {_field_} debe ser una imagen',
    integer: 'El campo {_field_} debe ser un entero',
    is_not: 'El valor del campo {_field_} debe ser diferente de {_value_}',
    is: 'El valor del campo {_field_} debe ser igual a {_value_}',
    length: 'El largo del campo {_field_} debe ser {length}',
    max: 'El campo {_field_} no debe ser mayor a {length} caracteres',
    max_value: 'El campo {_field_} debe de ser {max} o menor',
    mimes: 'El campo {_field_} debe ser un tipo de archivo válido',
    min: 'El campo {_field_} debe tener al menos {length} caracteres',
    min_value: 'El campo {_field_} debe ser {min} o superior',
    numeric: 'Debe contener solo números.',
    oneOf: 'El campo {_field_} debe ser un valor válido',
    regex: 'El formato del campo {_field_} no es válido',
    required: 'Campo requerido.',
    required_if: 'El campo {_field_} es obligatorio',
    size: 'El campo {_field_} debe ser menor a {size}KB',
  },
}

localize('es', es)
