import firebase from 'firebase/app'
import 'firebase/storage'

firebase.initializeApp({
  apiKey: 'AIzaSyBBA6RMPuP3TsxkA97Y_bR6RSD2zLQmL3Y',
  authDomain: 'contigo-do.firebaseapp.com',
  databaseURL: 'https://contigo-do.firebaseio.com',
  projectId: 'contigo-do',
  storageBucket: 'contigo-do.appspot.com',
  messagingSenderId: '614166170571',
  appId: '1:614166170571:web:2c7b3ffa6865d29382044e',
  measurementId: 'G-17GJK17HGL',
})
