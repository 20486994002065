import { RouteConfig } from 'vue-router'
import { lazyLoadViewComponent } from '@/routes/utils'

const dir = 'customers/products/documents'

export const documents: RouteConfig = {
  props: true,
  path: 'documents',
  component: lazyLoadViewComponent(`${dir}/Index.vue`),
  children: [
    {
      props: true,
      path: '',
      name: 'customer-product-documents',
      component: lazyLoadViewComponent(`${dir}/List.vue`),
    },
  ],
}
