import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store/index'

export interface AppState {
  loading: boolean
  snackbar: Snackbar
}

interface Snackbar {
  text: string
  value: boolean
  config: SnackbarConfig
}

interface SnackbarConfig {
  color?: string
  multiLine?: boolean
  timeout?: number
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
}

const initialSnackbar: Snackbar = {
  text: '',
  value: false,
  config: {
    color: 'info',
    multiLine: false,
    timeout: 6000,
    top: true,
    bottom: false,
    left: false,
    right: false,
  },
}

const name = 'app'

@Module({
  dynamic: true,
  name,
  namespaced: true,
  store,
})
class AppModule extends VuexModule implements AppState {
  loading = false
  snackbar: Snackbar = { ...initialSnackbar }

  @Mutation
  INIT_SNACKBAR({
    text,
    config,
  }: {
    text: string
    config?: SnackbarConfig
  }): void {
    this.snackbar.text = text
    this.snackbar.value = true

    if (config) {
      this.snackbar.config = {
        ...initialSnackbar.config,
        ...config,
      }
    }
  }

  @Mutation
  RESET_SNACKBAR(): void {
    this.snackbar = { ...initialSnackbar }
  }

  @Mutation
  SET_LOADING(loading: boolean): void {
    if (loading !== this.loading) {
      this.loading = loading
    }
  }

  @Action
  showSnackbar({
    text,
    config,
  }: {
    text: string
    config?: SnackbarConfig
  }): void {
    this.RESET_SNACKBAR()
    this.INIT_SNACKBAR({ text, config })
  }

  @Action
  dismissSnackbar(): void {
    this.RESET_SNACKBAR()
  }

  @Action
  startLoading(): void {
    this.SET_LOADING(true)
  }

  @Action
  stopLoading(): void {
    this.SET_LOADING(false)
  }
}

export const appModule = getModule(AppModule, store)
