import { RouteConfig } from 'vue-router'
import { lazyLoadViewComponent } from '@/routes/utils'
import { documents } from './documents'

const dir = 'customers/products'

export const products: RouteConfig = {
  props: true,
  path: 'products',
  component: lazyLoadViewComponent(`${dir}/Index.vue`),
  children: [
    {
      props: true,
      path: '',
      name: 'customer-products',
      component: lazyLoadViewComponent(`${dir}/List.vue`),
    },
    {
      props: true,
      path: ':productId',
      name: 'customer-product',
      component: lazyLoadViewComponent(`${dir}/Id.vue`),
      children: [documents],
    },
  ],
}
